<div class="px-2 md:px-8">
    <div class="px-1 mb-[15px] md:mb-8">
        <p class="text-quovo-red -mb-1 md:mb-3 text-xl md:text-3xl quovo-font-bold">
            {{ 'swiper.dishes-to-try' | translate }}</p>
        <p class="quovo-font-light mb-0 text-xs md:text-2xl">{{ 'swiper.dishes-to-try-description' | translate }}</p>
        <a class="hidden md:inline-block text-quovo-red underline quovo-font-light text-2xl hover:cursor-pointer"
            (click)="goToDishes()">{{ 'swiper.go-to-dishes' | translate }}</a>
    </div>

    <div>
        <div class="flex flex-col gap-y-[10px] mb-7">
            <ng-container *ngIf="dish?.length; else noDataRestaurant">
                <swiper-container #swiperEl slides-per-view="5" [breakpoints]="breakpoints" space-between="30"
                    speed="500" css-mode="true" mousewheel-control="{enabled: true, forceToAxis: true}"
                    class="hidden md:flex justify-items-stretch items-stretch mx-0">
                    <swiper-slide *ngFor="let item of dish; let i = index"
                        class="max-w-card md:pb-[12px] md:max-w-card-md md:self-stretch md:h-auto">
                        <ng-container *ngTemplateOutlet="randomDishes;context: { $implicit: i + 1, item: item }" />
                    </swiper-slide>
                </swiper-container>
                <div *ngFor="let item of dish | slice:0:5; let i = index" class="flex flex-col w-full md:hidden">
                    <ng-container *ngTemplateOutlet="randomDishesMobile; context: { $implicit: i + 1, item: item }" />
                </div>
            </ng-container>
        </div>
        <button class="flex md:hidden w-full rounded-md bg-quovo-red text-center text-white p-2.5 text-sm"
            (click)="goToDishes()">
            <span class="mx-auto">{{ 'swiper.go-to-dishes' | translate }}</span>
        </button>
    </div>

    <ng-template #noDataRestaurant>
        <div class="swiper-slide">
            {{'common.no-data' | translate}}
        </div>
    </ng-template>

    <ng-template #randomDishes let-item="item">
        <div class="flex flex-col shadow-[0px_3px_6px_#00000029] rounded-[15px] overflow-hidden bg-white h-full relative md:cursor-pointer"
            (click)="onCardClick(item)">
            <div>
                <div class="absolute top-5 right-5 bg-white w-8 h-8 rounded-full p-2 flex align-items-center justify-center hover:cursor-pointer"
                    (click)="onFavoriteClick($event, item)"
                >
                    <i class="fa-regular fa-heart"></i>
                </div>
                <img [src]="item.imageUrl" alt="" class="w-full object-cover aspect-square" loading="lazy">
            </div>
            <div class="flex flex-col p-[10px_14px_16px] h-full">
                <p class="text-[22px] lh-[30px] text-quovo-red mb-[0px] lg:mb-3">
                    {{ item.name }}
                </p>
                <p
                    class="text-quovo-gray-dark text-xs md:text-base line-clamp-2 lg:line-clamp-3 xl:line-clamp-2 text-left text-wrap mb-[15px]">
                    {{ item.description }}
                </p>
                <div class="mt-auto">
                    <p class="text-quovo-gray-dark text-sm text-left text-wrap overflow-ellipsis truncate">
                        <span>{{ getCityTag(item) }}</span>
                        <span class="px-1">|</span>
                        <span>{{ item.restaurantName }}</span>
                        <span class="px-1">|</span>
                        <span class="px-1">{{ getCuisineTag(item) }}</span>
                    </p>
                    <button class="w-full rounded-md bg-quovo-red text-center text-white p-1 text-sm">
                        <span class="mx-auto">Scopri la ricetta!</span>
                    </button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #randomDishesMobile let-item="item">
        <div class="rounded-lg" (click)="onCardClick(item)">
            <div [style.background-image]="'url(\'' + item.imageUrl + '\')'"
                class="w-full h-[68px] bg-cover bg-center bg-quovo-gray-dark bg-blend-overlay rounded-xl text-center flex flex-col items-center justify-center">
                <span class="text-white text-center text-xl font-bold line-clamp-1">{{item.name}}</span>
                <span class="text-white text-center text-sm">{{item.restaurantName}}</span>
            </div>
        </div>
    </ng-template>
</div>