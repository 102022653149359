import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DishService } from '../../../../services/dish.service';
import { UtilsService } from '../../../../services/utils.service';
import { PaginationModel } from '../../../../models/interfaces/pagination.model';
import { CommonModule } from '@angular/common';
import { InitializeSwiperFunction } from "../../../../functions/initialize-swiper.function";
import { CardItem } from "../../../../models/interfaces/card-item.model";
import { Router } from "@angular/router";
import { TruncateWordsPipe } from "../../../../pipes/truncateWords.pipe";
import { TranslateModule } from '@ngx-translate/core';
import {DishModel} from "../../../../models/interfaces/dish.model";
import {LanguageService} from "../../../../services/language.service";

@Component({
  selector: 'app-random-dishes',
  standalone: true,
  imports: [CommonModule, TruncateWordsPipe,TranslateModule],
  providers: [TruncateWordsPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA ],
  templateUrl: './random-dishes.component.html',
  styleUrl: './random-dishes.component.scss',
})
export class RandomDishesComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('randomDishes') swiperEl!: ElementRef;
  @Input() filteredRandomDishes!: any[];
  private destroy$: Subject<boolean> = new Subject<boolean>();
  protected dish!: DishModel[];
  pagination!: PaginationModel;
  initializeSwiperFunction!: InitializeSwiperFunction;

  breakpoints = {
    320: {
      slidesPerView: 1.25,
    },
    640: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 2.5,
    },
    1024: {
      slidesPerView: 4
    },
    1280: {
      slidesPerView: 5
    },
  };
  private unsubscribe = new Subject<void>();

  constructor(
    private dishService: DishService,
    private utils: UtilsService,
    private route: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.displayRandomDishes();
    this.languageService.getData$().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.displayRandomDishes();
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.dish = this.filteredRandomDishes;
    this.initializeSwiperFunction?.swiper(this.swiperEl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  displayRandomDishes() {
    this.pagination = this.utils.getHomePagination();
    this.dishService.getRandomDishes(this.pagination).pipe(
      takeUntil(this.destroy$.asObservable())).subscribe({
        next: (res: DishModel[]) => {
          this.dish = res;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  onCardClick(item: CardItem){
    this.route.navigate(['dish-details/'+ item.id]);
  }
  goToDishes() {
    this.route.navigate(['/dishes/all']);
  }

  getCityTag(item: any) {
    return item?.tags?.find((x: any) => x.tagCategoryId==1)?.tag
  }

  getCuisineTag(item: any) {
    return item?.tags?.find((x: any) => x.tagCategoryId==3)?.tag
  }

  onFavoriteClick($event: Event, item: CardItem) {
    $event.stopPropagation();
    this.dishService.addToFavorites(item.id).subscribe({
        next: (res) => {
          console.log(res);
          },
        error: (err) => {
          console.log(err);
        },
    });
  }
}
