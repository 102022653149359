import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChefService } from '../../../../services/chef.service';
import { UtilsService } from '../../../../services/utils.service';
import { Subject, takeUntil } from 'rxjs';
import { PaginationModel } from '../../../../models/interfaces/pagination.model';
import { RestaurantModel } from '../../../../models/interfaces/restaruant.model';
import { NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { InitializeSwiperFunction } from "../../../../functions/initialize-swiper.function";
import { CardItem } from "../../../../models/interfaces/card-item.model";
import { Router } from "@angular/router";
import {TruncateWordsPipe} from "../../../../pipes/truncateWords.pipe";
import { TranslateModule } from '@ngx-translate/core';
import {ChefModel} from "../../../../models/interfaces/chef.model";
import {LanguageService} from "../../../../services/language.service";

@Component({
  selector: 'app-random-chefs',
  standalone: true,
  templateUrl: './random-chefs.component.html',
  styleUrl: './random-chefs.component.scss',
  imports: [NgForOf, NgIf, NgTemplateOutlet, NgOptimizedImage, TruncateWordsPipe, TranslateModule],
  providers: [ TruncateWordsPipe ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class RandomChefsComponent implements OnInit, OnDestroy  , OnChanges {

  @ViewChild('randomRestaurants') swiperEl!: ElementRef;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  pagination!: PaginationModel;
  chefs!: ChefModel[];
  initializeSwiperFunction!: InitializeSwiperFunction;

  breakpoints = {
    320: {
      slidesPerView: 1.25,
    },
    640: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 2.5,
    },
    1024: {
      slidesPerView: 4,
    },
    1280: {
      slidesPerView: 5
    },
  };

  private unsubscribe = new Subject<void>();

  constructor(
    private chefService: ChefService,
    private utils: UtilsService,
    private route: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.getRandomChefs();
    this.languageService.getData$().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.getRandomChefs();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initializeSwiperFunction?.swiper(this.swiperEl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getRandomChefs() {
    this.pagination = this.utils.getHomePagination();
    this.chefService.getRandomChefs(this.pagination).pipe(
      takeUntil(this.destroy$.asObservable())).subscribe({
        next: (res: ChefModel[]) => {
          this.chefs = res;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  onCardClick(item: CardItem){
    this.route.navigate(['chef-details/'+ item.id]);
  }
  
  goToChefs() {
    this.route.navigate(['protagonists/all'])
  }

  getCityTag(item: any) {
    return item?.tags?.find((x: any) => x.tagCategoryId==1)?.tag
  }

  getCuisineTag(item: any) {
    return item?.tags?.find((x: any) => x.tagCategoryId==3)?.tag
  }

  onFavoriteClick(event: Event, item: CardItem) {
    event.stopPropagation();
    this.chefService.addToFavorites(item.id).subscribe({
      next: (res) => {
        console.log(res);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

}
