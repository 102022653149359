import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PaginationModel } from '../../../../models/interfaces/pagination.model';
import { Subject, takeUntil } from 'rxjs';
import { ExperienceService } from '../../../../services/experience.service';
import { UtilsService } from '../../../../services/utils.service';
import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { CardItem } from '../../../../models/interfaces/card-item.model';
import { InitializeSwiperFunction } from "../../../../functions/initialize-swiper.function";
import { Router } from "@angular/router";
import { TruncateWordsPipe } from "../../../../pipes/truncateWords.pipe";
import { TransformSizeLetterPipe } from "../../../../pipes/transform-size-letter.pipe";
import { TranslateModule } from '@ngx-translate/core';
import {ExperienceModel} from "../../../../models/interfaces/experience.model";
import {LanguageService} from "../../../../services/language.service";

@Component({
  selector: 'app-random-experiences',
  standalone: true,
  imports: [CommonModule, NgIf, NgOptimizedImage, TruncateWordsPipe, TransformSizeLetterPipe,TranslateModule],
  providers: [TruncateWordsPipe , TransformSizeLetterPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './random-experiences.component.html',
  styleUrl: './random-experiences.component.scss',
})

export class RandomExperiencesComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('randomExperiences') swiperEl!: ElementRef;
  @Input() filteredRandomExperiences!: any[];
  protected experiences!: ExperienceModel[];
  pagination!: PaginationModel;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  initializeSwiperFunction!: InitializeSwiperFunction;

  breakpoints = {
    320: {
      slidesPerView: 1.25,
    },
    640: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 2.5,
    },
    1024: {
      slidesPerView: 4,
    },
    1280: {
      slidesPerView: 5
    },
  };

  private unsubscribe = new Subject<void>();

  constructor(
    private experienceService: ExperienceService,
    private utils: UtilsService,
    private route: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.getRandomExperiences();
    this.languageService.getData$().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.getRandomExperiences();
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.experiences = this.filteredRandomExperiences;
    this.initializeSwiperFunction?.swiper(this.swiperEl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Get Random Experience
  getRandomExperiences() {
    this.pagination = this.utils.getHomePagination();
    this.experienceService.getRandomExperiences(this.pagination).pipe(
      takeUntil(this.destroy$.asObservable())).subscribe({
        next: (res) => {
          this.experiences = res;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  onCardClick(item: CardItem){
    this.route.navigate(['experience-details/'+ item.id]);
  }
  goToExperiences() {
    this.route.navigate(['experiences/all'])
  }

  getCityTag(item: any) {
    return item?.tags?.find((x: any) => x.tagCategoryId==1)?.tag
  }

  getTypologyTag(item: any) {
    return item?.tags?.find((x: any) => x.tagCategoryId==7)?.tag
  }

  onFavoriteClick(event: Event, item: CardItem) {
    event.stopPropagation();
    this.experienceService.addToFavorites(item.id).subscribe({
      next: (res) => {
        console.log(res);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
