import { FilteredSwipersModel } from '../../models/interfaces/filteredSwipers.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavbarComponent } from "../navbar/navbar.component";
import { SwiperComponent } from './swiper-component/swiper-component.component';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ObserversModule } from '@angular/cdk/observers';
import { ChefService } from '../../services/chef.service';
import { RandomChefsComponent } from './swiper-component/random-chefs/random-chefs.component';
import { RandomExperiencesComponent } from './swiper-component/random-experiences/random-experiences.component';
import { ExperienceService } from '../../services/experience.service';
import { FooterComponent } from '../footer/footer.component';
import { FilterService } from '../../services/filter.service';
import { UtilsService } from '../../services/utils.service';
import { Subject, takeUntil } from 'rxjs';
import { SearchInputModel } from '../../models/interfaces/search-input.model';
import { SearchCategoryEnum } from '../../models/enums/search-category.enum';
import { FilterInputModel } from '../../models/interfaces/filter-input.model';
import { ExperienceModel } from "../../models/interfaces/experience.model";
import { RestaurantModel } from "../../models/interfaces/restaruant.model";
import { RestaurantService } from "../../services/restaurant.service";
import { DishModel } from "../../models/interfaces/dish.model";
import { DishService } from "../../services/dish.service";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    NavbarComponent,
    SwiperComponent,
    SearchPanelComponent,
    RouterLink,
    RouterLinkActive,
    CommonModule,
    NgOptimizedImage,
    HttpClientModule,
    ObserversModule,
    RandomChefsComponent,
    RandomExperiencesComponent,
    FooterComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  providers: [ChefService, ExperienceService, FilterService],
})
export class HomeComponent implements OnInit, OnDestroy {

  filteredSwipers: FilteredSwipersModel = {
    filteredRandomRestaurants: [],
    filteredRandomDishes: [],
    filteredRandomExperiences: []
  };
  private unsubscribeAll$ = new Subject<void>();

  constructor(
    private filterService: FilterService,
    private utils: UtilsService,
    private restaurantService: RestaurantService,
    private dishService: DishService,
    private experienceService: ExperienceService,
  ) { }

  ngOnInit(): void {
    console.log(this.filteredSwipers);
  }

  onSearch(event: SearchInputModel) {

    let searchTerm = { name: event.searchTerm } as Partial<FilterInputModel>;

    switch (event.filter) {
      case SearchCategoryEnum.RESTAURANTS:
        if (!event.searchTerm) {
          this.getRandomRestaurants();
        } else {
          this.filterRestaurants(searchTerm);
        }
        break;

      case SearchCategoryEnum.DISHES:
        if (!event.searchTerm) {
          this.getRandomDishes();
        } else {
          this.filterDishes(searchTerm);
        }
        break;

      case SearchCategoryEnum.EXPERIENCES:
        if (!event.searchTerm) {
          this.getRandomExperiences();
        } else {
          this.filterExperiences(searchTerm);
        }
        break;
    }
  }

  filterRestaurants(searchTerm: Partial<FilterInputModel>) {
    this.filterService.filterRestaurants(searchTerm).pipe().subscribe({
        next: (response) => {
          this.filteredSwipers.filteredRandomRestaurants = response;
        },
        error: (err) => {
          console.log(err.message);
        },
      });
  }

  filterDishes(searchTerm: Partial<FilterInputModel>) {
    this.filterService.filterDishes(searchTerm).pipe().subscribe({
        next: (response) => {
          this.filteredSwipers.filteredRandomDishes = response;
        },
        error: (err) => {
          console.log(err.message);
        },
      });
  }

  filterExperiences(searchTerm: Partial<FilterInputModel>) {
    this.filterService.filterExperiences(searchTerm).pipe().subscribe({
        next: (response) => {
          this.filteredSwipers.filteredRandomExperiences = response;
        },
        error: (err) => {
          console.log(err.message);
        },
      });
  }

  getRandomRestaurants() {
    const pagination = this.utils.getHomePagination();
    this.restaurantService.getRandomRestaurants(pagination).pipe().subscribe({
        next: (res: RestaurantModel[]) => {
          this.filteredSwipers.filteredRandomRestaurants = res;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getRandomDishes() {
    const pagination = this.utils.getHomePagination();
    this.dishService.getRandomDishes(pagination).pipe().subscribe({
        next: (res: DishModel[]) => {
          this.filteredSwipers.filteredRandomDishes = res;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getRandomExperiences() {
    const pagination = this.utils.getHomePagination();
    this.experienceService.getRandomExperiences(pagination).pipe().subscribe({
        next: (res: ExperienceModel[]) => {
          this.filteredSwipers.filteredRandomExperiences = res;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }
}
