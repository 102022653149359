<footer class="bg-quovo-beige"
   style="
      margin-top: 200px;
     position: relative;
     bottom: 0;
     left: 0;
     width: 100%;
">
  <div class="mx-auto max-w-7xl p-6 lg:px-8">
    <div class="xl:grid xl:grid-cols-3 xl:gap-8">
      <div class="text-left">
        <img class="h-20 mb-3" src="assets/images/quovofinal2024.svg" alt="Quovo"/>
        <p class="text-sm leading-6 text-quovo-red">{{ 'footer.follow' | translate }}</p>
        <div class="flex space-x-6">
          <a class="text-quovo-red hover:text-quovo-gray-dark" href="https://www.facebook.com/QuovoItalia" target="_blank">
            <i class="fa-brands fa-facebook fa-xl"></i>
          </a>
          <a class="text-quovo-red hover:text-quovo-gray-dark" href="https://www.instagram.com/quovoitalia/" target="_blank">
            <i class="fa-brands fa-instagram fa-xl"></i>
          </a>
        </div>
      </div>
      <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <p class="text-xl quovo-font-bold text-quovo-red">{{ 'footer.navigate' | translate }}</p>
            <ul role="list" class="mt-6 pl-0">
              <li>
                <a routerLink="/restaurants/all" class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline hover:cursor-pointer">{{ 'footer.restaurants' | translate }}</a>
              </li>
              <li>
                <a routerLink="/dishes/all" class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline hover:cursor-pointer">{{ 'footer.dishes' | translate }}</a>
              </li>
              <li>
                <a routerLink="/protagonists/all" class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline hover:cursor-pointer">{{ 'footer.chefs' | translate }}</a>
              </li>
              <li>
                <a routerLink="/experiences/all" class="text-sm text-quovo-red hover:text-quovo-gray-dark no-underline hover:cursor-pointer">{{ 'footer.experiences' | translate }}</a>
              </li>
            </ul>
          </div>
          <div class="mt-10 md:mt-0">
            <p class="text-xl quovo-font-bold text-white">&nbsp;</p>
            <ul role="list" class="mt-6 pl-0">
              <li>
                <a routerLink="/" class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline hover:cursor-pointer">{{ 'footer.discover' | translate }}</a>
              </li>
              <li>
                <a :href="item.href" class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline hover:cursor-pointer">{{ 'footer.blog' | translate }}</a>
              </li>
              <li>
                <a class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline hover:cursor-pointer">{{ 'footer.restaurant?' | translate }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <p class="text-xl quovo-font-bold text-quovo-red">{{ 'footer.utils' | translate }}</p>
            <ul role="list" class="mt-6 pl-0">
              <li>
                <a :href="item.href" class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline">{{ 'footer.conditions' | translate }}</a>
              </li>
              <li>
                <a :href="item.href" class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline">Cookie Policy</a>
              </li>
              <li>
                <a :href="item.href" class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline">Privacy Policy</a>
              </li>
              <li>
                <a :href="item.href" class="text-lg text-quovo-red hover:text-quovo-gray-dark no-underline">{{ 'footer.legal' | translate }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

