<div class="hidden md:flex w-full md:h-filters h-filters-mobile items-center justify-items-center md:mb-[60px]">
    <div class="hidden md:flex md:h-filters h-filters-mobile wrapper">
        <video playsinline autoplay [muted]="true" loop poster="assets/images/poster-2.png" id="slide1"
            class="hidden md:flex w-full md:h-filters h-filters-mobile items-center justify-items-center"
            *ngIf="isSelectedCategory(searchCategory.DISHES)"
            [style.background-image]="'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'"
            [style.background-position]="'center center'" [style.background-size]="'cover'"
            [style.background-repeat]="'no-repeat'">
            <source src="assets/videos/Piatti.mp4" type="video/mp4">
        </video>
        <video playsinline autoplay [muted]="true" loop poster="assets/images/poster-1.png" id="slide2"
            class="hidden md:flex w-full md:h-filters h-filters-mobile items-center justify-items-center"
            *ngIf="isSelectedCategory(searchCategory.RESTAURANTS)"
            [style.background-image]="'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'"
            [style.background-position]="'center center'" [style.background-size]="'cover'"
            [style.background-repeat]="'no-repeat'">
            <source src="assets/videos/Ristoranti.mp4" type="video/mp4">
        </video>
        <video playsinline autoplay [muted]="true" loop poster="assets/images/poster-3.png" id="slide3"
            class="hidden md:flex w-full md:h-filters h-filters-mobile items-center justify-items-center"
            *ngIf="isSelectedCategory(searchCategory.EXPERIENCES)"
            [style.background-image]="'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'"
            [style.background-position]="'center center'" [style.background-size]="'cover'"
            [style.background-repeat]="'no-repeat'">
            <source src="assets/videos/Esperienze.mp4" type="video/mp4">
        </video>
    </div>
    <div class="relative flex flex-col items-center justify-center h-full w-full">
        <div class="sm:w-1/2 2xl:w-2/5">
            <div class="hidden md:flex flex-row w-full items-center justify-between">
                <button
                    class="bg-quovo-gray flex justify-items-center items-center rounded-tl-2xl text-gray-400 lg:text-lg xl:text-2xl px-8 lg:px-6 h-24 w-full"
                    [ngClass]="isSelectedCategory(searchCategory.RESTAURANTS) ? 'text-quovo-gray-dark border-r-2' : ''"
                    (click)="onFilterClick(searchCategory.RESTAURANTS)">
                    <i class="fa-solid fa-utensils text-4xl lg:text-sm xl:text-4xl w-full lg:w-1/3"></i>
                    <span class="px-2 hidden lg:block">{{ 'search.restaurants' | translate }}</span>
                </button>
                <button
                    class="bg-quovo-gray flex justify-items-center items-center text-gray-400 lg:text-lg xl:text-2xl px-8 lg:px-6 h-24 w-full"
                    [ngClass]="isSelectedCategory(searchCategory.DISHES) ? 'text-quovo-gray-dark border-r-2 border-l-2' : ''"
                    (click)="onFilterClick(searchCategory.DISHES)">
                    <i class="fa-solid fa-pizza-slice text-4xl lg:text-sm xl:text-4xl w-full lg:w-1/3"></i>
                    <span class="px-2 hidden lg:block">{{ 'search.dishes' | translate }}</span>
                </button>
                <button
                    class="bg-quovo-gray flex justify-items-center items-center rounded-tr-2xl text-gray-400 lg:text-lg xl:text-2xl px-8 lg:px-6 h-24 w-full"
                    [ngClass]="isSelectedCategory(searchCategory.EXPERIENCES) ? 'text-quovo-gray-dark border-l-2' : ''"
                    (click)="onFilterClick(searchCategory.EXPERIENCES)">
                    <i class="fa-solid fa-wine-glass text-4xl lg:text-sm xl:text-4xl w-full lg:w-1/3"></i>
                    <span class="px-2 hidden lg:block">{{ 'search.experiences' | translate }}</span>
                </button>
            </div>
            <div
                class="flex flex-row items-center  rounded-bl-2xl rounded-br-2xl md:bg-quovo-red py-6 w-full justify-between mb-2">
                <div class="w-full px-8">
                    <div class="flex rounded-md shadow-sm">
                        <button type="button" (click)="onSearchCategory(selectedFilter)"
                            class="relative md:hidden -ml-px inline-flex items-center gap-x-1.5 rounded-l-xl px-3 py-2 text-2xl font-semibold text-gray-900 bg-white">
                            <i class="fas fa-search -ml-0.5 h-5 w-5 text-quovo-gray-dark"></i>
                        </button>
                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <input
                                class="rounded-r-xl md:rounded-r-none md:rounded-l-xl w-full h-14 border-0 focus:ring-0 placeholder-gray-300 lg:placeholder:text-2xl"
                                [placeholder]="selectedFilterObject.placeholder" type="text"
                                [formControl]="searchFormControl" (keyup.enter)="onSearchCategory(selectedFilter)">
                        </div>
                        <button type="button" *ngIf="showClearButton()" (click)="onClearFilter()"
                            class="hidden relative -ml-px md:inline-flex items-center gap-x-1.5 px-3 py-2 text-2xl font-semibold text-gray-900 bg-white">
                            <i class="fas fa-times -ml-0.5 h-5 w-5 text-quovo-gray-dark"></i>
                        </button>
                        <button type="button" (click)="onSearchCategory(selectedFilter)"
                            class="hidden relative -ml-px md:inline-flex items-center gap-x-1.5 border-1 border-l-gray-500 rounded-r-xl px-3 py-2 text-2xl font-semibold text-gray-900 bg-white active:bg-violet-700">
                            <i class="fas fa-search -ml-0.5 h-5 w-5 text-quovo-gray-dark"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-white font-light md:font-bold lg:text-xl">
            {{selectedFilterObject.descriptionOfChosenOption}}
        </div>
    </div>
</div>

<div class="flex flex-row mb-[30px] md:hidden">
    <ng-container>
        <swiper-container #swiperEl slides-per-view="1" [breakpoints]="breakpoints" space-between="30" speed="500"
            initial-slide="1" class="w-full" pagination="{ el: '.swiper-pagination', clickable: true}"
            (swiperactiveindexchange)="onSwiperChange($event)">
            <swiper-slide *ngFor="let item of slides; let i = index">
                <ng-container
                    *ngTemplateOutlet=" searchSlides;context: { $implicit: i + 1, item: item }"></ng-container>
            </swiper-slide>
        </swiper-container>
    </ng-container>
</div>

<ng-template #searchSlides let-item="item">
    <div class="flex h-filters-mobile items-center justify-items-center mx-auto">
        <div class="wrapper h-filters-mobile">
            <video playsinline autoplay [muted]="true" loop id="mobileSlide1"
                poster="assets/images/poster-1.png"
                class="flex w-full h-filters-mobile items-center justify-items-center"
                *ngIf="isSelectedCategory(searchCategory.DISHES)" [style.background-position]="'center center'"
                [style.background-size]="'cover'" [style.background-repeat]="'no-repeat'">
                <source src="assets/videos/Piatti.mp4" type="video/mp4">
            </video>
            <video playsinline autoplay [muted]="true" loop id="mobileSlide2"
                poster="assets/images/poster-2.png"
                class="flex w-full h-filters-mobile items-center justify-items-center"
                *ngIf="isSelectedCategory(searchCategory.RESTAURANTS)" [style.background-position]="'center center'"
                [style.background-size]="'cover'" [style.background-repeat]="'no-repeat'">
                <source src="assets/videos/Ristoranti.mp4" type="video/mp4">
            </video>
            <video playsinline autoplay [muted]="true" loop id="mobileSlide3"
                poster="assets/images/poster-3.png"
                class="flex w-full h-filters-mobile items-center justify-items-center"
                *ngIf="isSelectedCategory(searchCategory.EXPERIENCES)" [style.background-position]="'center center'"
                [style.background-size]="'cover'" [style.background-repeat]="'no-repeat'">
                <source src="assets/videos/Esperienze.mp4" type="video/mp4">
            </video>
        </div>
        <div class="relative flex flex-col items-center justify-center h-full w-full">
            <div class="text-white font-light text-2xl ">
                {{item.headerOfChosenOption}}
            </div>
            <div
                class="flex items-center rounded-bl-2xl rounded-br-2xl md:bg-quovo-red w-full sm:w-1/2 xl:w-3/5 2xl:w-2/5 justify-between mb-1">
                <div class="w-full px-8">
                    <div class="flex rounded-md shadow-sm">
                        <button type="button" (click)="onSearchCategory(selectedFilter)"
                            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-l-xl px-3 py-2 text-2xl font-semibold text-gray-900 bg-white">
                            <i class="fas fa-search -ml-0.5 h-5 w-5 text-quovo-gray-dark"></i>
                        </button>
                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <input
                                class="md:rounded-r-none md:rounded-l-xl w-full h-14 border-0 focus:ring-0 placeholder-gray-300 lg:placeholder:text-2xl"
                                [ngClass]="showClearButton() ? 'rounded-r-0' : 'rounded-r-xl '"
                                [placeholder]="selectedFilterObject.placeholder" type="text"
                                [formControl]="searchFormControl" (keyup.enter)="onSearchCategory(selectedFilter)">
                        </div>
                        <button type="button" *ngIf="showClearButton()" (click)="onClearFilter()"
                            class="relative -ml-px md:inline-flex items-center gap-x-1.5 rounded-r-xl px-3 py-2 text-2xl font-semibold text-gray-900 bg-white">
                            <i class="fas fa-times -ml-0.5 h-5 w-5 text-quovo-gray-dark"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="text-white font-light md:font-bold lg:text-xl">
                {{item.descriptionOfChosenOption }}
            </div>
        </div>
    </div>
</ng-template>
