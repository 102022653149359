import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SearchCategoryEnum } from '../../../models/enums/search-category.enum';
import { SearchInputModel } from '../../../models/interfaces/search-input.model';
import { FilterService } from '../../../services/filter.service';
import {TranslateModule,TranslateService} from "@ngx-translate/core";
import {SlideModel} from "../../../models/interfaces/slide.model";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-search-panel',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule,TranslateModule],
  templateUrl: './search-panel.component.html',
  styleUrl: './search-panel.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA ]
})

export class SearchPanelComponent implements OnInit {

  @Output() onSearch = new EventEmitter<SearchInputModel>();
  searchFormControl = new FormControl<string>('');
  oldSearchFormControl = new FormControl<string>('');
  searchCategory = SearchCategoryEnum;
  selectedFilter: string = SearchCategoryEnum.DISHES;
  oldFilter: string = SearchCategoryEnum.DISHES;
  slides = [
    {
      category: SearchCategoryEnum.RESTAURANTS,
      descriptionOfChosenOption: this.translate.instant('search.restaurant-search-suggestion'),
      titleOfChosenOption: this.translate.instant('search.restaurants'),
      backgroundImg: "../../../../assets/images/gatteau_1.png",
      placeholder: this.translate.instant('search.restaurant-placeholder'),
      headerOfChosenOption: this.translate.instant('search.eating'),
      video: "../../../../assets/videos/Ristoranti.mp4",
    },
    {
      category: SearchCategoryEnum.DISHES,
      descriptionOfChosenOption: this.translate.instant('search.plate-search-suggestion'),
      titleOfChosenOption: this.translate.instant('search.dishes'),
      placeholder: this.translate.instant('search.dish-placeholder'),
      backgroundImg: "../../../../assets/images/immagine-home.jpg",
      headerOfChosenOption: this.translate.instant('search.dishes'),
      video: "../../../../assets/videos/Piatti.mp4",
    },
    {
      category: SearchCategoryEnum.EXPERIENCES,
      descriptionOfChosenOption: this.translate.instant('search.experiences-search-suggestion'),
      titleOfChosenOption: this.translate.instant('search.experiences'),
      placeholder: this.translate.instant('search.experience-placeholder'),
      backgroundImg: "../../../../assets/images/pasta_fresca_1.png",
      headerOfChosenOption: this.translate.instant('search.experiences'),
      video: "../../../../assets/videos/Esperienze.mp4",
    }
  ]
  selectedFilterObject: SlideModel = this.slides[1];

  breakpoints = {
    320: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    }
  };


  constructor(
    private filterService: FilterService,
    public translate : TranslateService,
    private scroller: ViewportScroller,
    ) {}


  ngOnInit(): void {
    this.filterService.setFilter('dishes');
    this.onFilterClick(this.selectedFilter);
    //on language change reload the slides
    this.translate.onLangChange.subscribe((lang) => {
      window.location.reload();
    });
  }

  onSearchCategory(filter: string) {
    if (this.searchFormControl.value) {
      this.onSearch.emit({searchTerm: this.searchFormControl.value, filter: filter});
      this.scroller.scrollToAnchor(this.selectedFilter);
    }
  }

  onFilterClick(filter: string) {
    this.oldFilter = this.selectedFilter;
    this.selectedFilter = filter;
    this.filterService.setFilter(filter);
    this.selectedFilterObject = this.slides.filter(item => item.category === filter)[0];
    this.oldSearchFormControl = this.searchFormControl;
    this.searchFormControl = new FormControl<string>('');
    if (this.oldFilter != this.selectedFilter && this.oldSearchFormControl.value != '') {
      this.onSearch.emit({searchTerm: null, filter: this.oldFilter});
    }
  }

  onSwiperChange(event: any) {
    const index = event.detail[0].activeIndex;
    const res = this.slides[index];
    if (res) {
      this.onFilterClick(res.category)
    }
  }

  onClearFilter() {
    this.searchFormControl = new FormControl<string>('');
    this.oldSearchFormControl = this.searchFormControl;
    this.onSearch.emit({searchTerm: null, filter: this.selectedFilter});
  }

  isDefaultFilter(filter: string): boolean {
    return this.filterService.getDefaultFilter() === filter;
  }

  showClearButton() {
    return this.searchFormControl.value != '';
  }

  isSelectedCategory(category: string) {
    return this.selectedFilter === category;
  }
}
