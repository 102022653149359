import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: 'capitalize'
})

export class TransformSizeLetterPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    return value?.charAt(0)?.toUpperCase() + value?.slice(1)?.toLowerCase();
  }
}
