<div class="px-2 md:px-8">
  <div class="px-1 mb-[15px] md:mb-8">
    <p class="text-quovo-red -mb-1 md:mb-[10px] text-xl md:text-3xl quovo-font-bold">{{ 'swiper.restaurant-choices' | translate }}</p>
    <p class="quovo-font-light mb-0 md:mb-3 text-xs md:text-2xl">{{ 'swiper.choices-description' | translate }}</p>
    <a class="hidden md:inline-block text-quovo-red underline quovo-font-light text-2xl hover:cursor-pointer" (click)="gotoRestaurnts()"
       routerLink="/restaurants/all">{{ 'swiper.go-to-restaurants' | translate }}</a>
  </div>

  <div>
    <div>
      <ng-container *ngIf="restaurant?.length; else noDataRestaurant">
        <swiper-container #swiperEl slides-per-view="5" [breakpoints]="breakpoints" space-between="30" speed="500" css-mode="true" mousewheel-control="{enabled: true, forceToAxis: true}" class="flex justify-items-stretch items-stretch mb-[16px] md:mb-[0px]">
          <swiper-slide *ngFor="let item of restaurant; let i = index" class="max-w-card pb-[12px] md:max-w-card-md self-stretch h-auto">
            <ng-container *ngTemplateOutlet="randomRestaurants;context: { $implicit: i + 1, item: item }"></ng-container>
          </swiper-slide>
        </swiper-container>
      </ng-container>
    </div>
    <button class="flex md:hidden w-full rounded-md bg-quovo-red text-center text-white p-2.5 text-sm" (click)="gotoRestaurnts()">
      <span class="mx-auto">{{ 'swiper.go-to-restaurants' | translate }}</span>
    </button>
  </div>

  <ng-template #noDataRestaurant>
    <div class="swiper-slide">
      {{'common.no-data' | translate}}
    </div>
  </ng-template>

  <ng-template #randomRestaurants let-item="item">
    <div class="flex flex-col shadow-[0px_3px_6px_#00000029] rounded-[15px] overflow-hidden bg-white h-full relative md:cursor-pointer" (click)="onCardClick(item)">
      <div class="relative">
          <div class="absolute top-5 right-5 bg-white w-8 h-8 rounded-full p-2 flex align-items-center justify-center hover:cursor-pointer"
               (click)="onFavoriteClick($event, item)">
              <i class="fa-regular fa-heart"></i>
          </div>
        <img [src]="item.imageUrl" alt="" class="w-full object-cover rounded-t-xl aspect-square sm:col-span-2 lg:col-span-full" loading="lazy">
      </div>
      <div class="flex flex-col p-[10px_14px_16px] h-full">
        <p class="text-[22px] lh-[30px] text-quovo-red mb-[0px] lg:mb-3">
          {{ item.name }}
        </p>
        <p class="text-quovo-gray-dark text-xs md:text-base line-clamp-2 lg:line-clamp-3 xl:line-clamp-2 text-left text-wrap mb-[15px]">
          {{ item.description }}
        </p>
        <div class="mt-auto">
          <p class="text-quovo-gray-dark text-[10px] md:text-[14px] text-wrap overflow-ellipsis truncate mb-2">
            <span>{{ getCityTag(item) }}</span>
            <span class="px-1">|</span>
            <span>{{ getPriceTag(item) }}</span>
            <span class="px-1">|</span>
            <span>{{ getSpecialtyTag(item) }}</span>
          </p>
          <button class="flex flex-row items-center justify-between bg-slate-900 w-16 py-0.5 px-2 rounded-md">
            <img  class="w-4 h-4 flex" src="assets/images/tripAdvisor.png" alt="..">
            <span class="text-white text-sm px-2">{{ item.vote }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
