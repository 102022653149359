<div id="restaurants"></div>
<br>
<br>
<div class="mb-[16px] lg:mb-[48px]">
    <app-random-restaurants [filteredRandomRestaurants]="filteredSwipers.filteredRandomRestaurants"></app-random-restaurants>
    <div id="dishes"></div>
    <br>
    <br>
</div>
<div class="mb-[16px] lg:mb-[48px]">
    <app-random-dishes [filteredRandomDishes]="filteredSwipers.filteredRandomDishes"></app-random-dishes>
</div>
<div class="mb-[16px] lg:mb-[48px]">
    <app-random-chefs class="mt-2"></app-random-chefs>
    <div id="experiences"></div>
    <br>
    <br>
</div>
<div class="mb-[16px] lg:mb-[48px]" >
    <app-random-experiences [filteredRandomExperiences]="filteredSwipers.filteredRandomExperiences"></app-random-experiences>
</div>
