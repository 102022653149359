import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { RestaurantModel } from '../../../../models/interfaces/restaruant.model';
import { RestaurantService } from '../../../../services/restaurant.service';
import { PaginationModel } from '../../../../models/interfaces/pagination.model';
import { UtilsService } from '../../../../services/utils.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { CardItem } from '../../../../models/interfaces/card-item.model';
import { Router } from "@angular/router";
import { InitializeSwiperFunction } from "../../../../functions/initialize-swiper.function";
import { TruncateWordsPipe } from "../../../../pipes/truncateWords.pipe";
import { TransformSizeLetterPipe } from "../../../../pipes/transform-size-letter.pipe";
import { TranslateModule } from '@ngx-translate/core';
import {LanguageService} from "../../../../services/language.service";

@Component({
  selector: 'app-random-restaurants',
  standalone: true,
  imports: [CommonModule, NgIf, NgOptimizedImage, TruncateWordsPipe , TransformSizeLetterPipe,TranslateModule ],
  providers:[TruncateWordsPipe , TransformSizeLetterPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './random-restaurants.component.html',
  styleUrl: './random-restaurants.component.scss',
})

export class RandomRestaurantsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filteredRandomRestaurants!: RestaurantModel[];
  @ViewChild('randomRestaurants') swiperEl!: ElementRef;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  restaurant!: RestaurantModel[];
  pagination!: PaginationModel;
  initializeSwiperFunction!: InitializeSwiperFunction;

  breakpoints = {
    320: {
      slidesPerView: 1.25,
    },
    640: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 2.5,
    },
    1024: {
      slidesPerView: 4,
    },
    1280: {
      slidesPerView: 5
    },
  };
  private unsubscribe = new Subject<void>();

  constructor(
    private restaurantService: RestaurantService,
    private utils: UtilsService,
    private route: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.getRandomRestaurant();
    this.languageService.getData$().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.getRandomRestaurant();
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.restaurant = this.filteredRandomRestaurants;
    this.initializeSwiperFunction?.swiper(this.swiperEl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getCityTag(item: any) {
    return item?.tags?.find((x: any) => x.tagCategoryId==1)?.tag
  }

  getPriceTag(item: any) {
    return item?.tags?.find((x: any) => x.tagCategoryId==2)?.tag
  }

  getSpecialtyTag(item: any) {
    return item?.tags?.find((x: any) => x.tagCategoryId==9)?.tag
  }

  // Get Random Restaurant
  getRandomRestaurant() {
    this.pagination = this.utils.getHomePagination();
    this.restaurantService.getRandomRestaurants(this.pagination).pipe(
      takeUntil(this.destroy$.asObservable())).subscribe({
        next: (res: RestaurantModel[]) => {
          this.restaurant = res;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  onCardClick(item: CardItem){
    this.route.navigate(['restaurant-details/'+ item.id]);
  }

  gotoRestaurnts() {
    this.route.navigate(['/restaurants/all']);
  }

  onFavoriteClick(event: Event, item: CardItem) {
    event.stopPropagation();
    this.restaurantService.addToFavorites(item.id).subscribe({
      next: (res) => {
        console.log(res);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
