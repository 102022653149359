<div class="px-2 md:px-8">
  <div class="px-1 mb-[15px] md:mb-8">
    <p class="text-quovo-red -mb-1 md:mb-[10px] text-xl md:text-3xl quovo-font-bold">{{ 'swiper.protagonists-title' | translate }}</p>
    <p class="quovo-font-light mb-0 md:mb-3 text-sm md:text-2xl">{{ 'swiper.protagonists-description' | translate }}</p>
    <a class="hidden md:inline-block text-quovo-red underline quovo-font-light text-2xl hover:cursor-pointer" (click)="goToChefs()"
       routerLink="/restaurants/all">{{ 'swiper.discover-protagonists' | translate }}</a>
  </div>

  <div>
    <div>
      <ng-container *ngIf="chefs?.length; else noDataChef">
        <swiper-container #swiperEl  slides-per-view="5" [breakpoints]="breakpoints" space-between="30" speed="500" css-mode="true" mousewheel-control="{enabled: true, forceToAxis: true}" class="flex justify-items-stretch items-stretch mb-[16px] md:mb-[0px]">
          <swiper-slide *ngFor="let item of chefs; let i=index" class="max-w-card pb-[12px] md:max-w-card-md self-stretch h-auto">
            <ng-container *ngTemplateOutlet="randomChefs ; context:{ $implicit: i+1 , item: item }"></ng-container>
          </swiper-slide>
        </swiper-container>
      </ng-container>
    </div>
    <button class="flex md:hidden w-full rounded-md bg-quovo-red text-center text-white p-2.5 text-sm" (click)="goToChefs()">
      <span class="mx-auto">{{ 'swiper.go-to-protagonists' | translate }}</span>
    </button>
  </div>


  <ng-template #noDataChef>
    <div class="swiper-slide">
      {{'common.no-data' | translate}}
    </div>
  </ng-template>

  <ng-template #randomChefs let-item="item">
    <div class="flex flex-col shadow-[0px_3px_6px_#00000029] rounded-[15px] overflow-hidden bg-white h-full relative md:cursor-pointer" (click)="onCardClick(item)">
      <div class="relative">
        <div class="absolute top-5 right-5 bg-white w-8 h-8 rounded-full p-2 flex align-items-center justify-center hover:cursor-pointer"
        (click)="onFavoriteClick($event, item)"
        >
          <i class="fa-regular fa-heart"></i>
        </div>
        <img [src]="item.imageUrl" alt="{{item.name}}" class="w-full object-cover rounded-t-xl aspect-square sm:col-span-2 lg:col-span-full" loading="lazy">
      </div>
      <div class="flex flex-col p-[10px_14px_16px] h-full">
        <p class="text-[22px] lh-[30px] text-quovo-red mb-[0px] lg:mb-3">
          {{ item.name }}
        </p>
        <p class="text-quovo-gray-dark text-xs md:text-base line-clamp-2 lg:line-clamp-3 xl:line-clamp-2 text-left text-wrap mb-2">
          {{ item.description }}
        </p>
        <p class="text-quovo-gray-dark text-[10px] md:text-[14px] text-left text-wrap overflow-ellipsis truncate mt-auto mb-0">
          <span>{{ getCityTag(item) }}</span>
          <span class="px-1">|</span>
          <span>{{ item.restaurantName }}</span>
        </p>
      </div>
    </div>
  </ng-template>
</div>

