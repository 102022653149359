import { Component, Input, OnInit } from '@angular/core';
import { RandomDishesComponent } from './random-dishes/random-dishes.component';
import { RandomRestaurantsComponent } from './random-restaurants/random-restaurants.component';
import { RandomExperiencesComponent } from './random-experiences/random-experiences.component';
import { RandomChefsComponent } from './random-chefs/random-chefs.component';
import { DishService } from '../../../services/dish.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { FilteredSwipersModel } from '../../../models/interfaces/filteredSwipers.model';

@Component({
  selector: 'app-swiper-component',
  standalone: true,
  imports: [
      RandomRestaurantsComponent,
      RandomDishesComponent,
      RandomExperiencesComponent,
      RandomChefsComponent
  ],
  providers: [RestaurantService, DishService],
  templateUrl: './swiper-component.component.html',
  styleUrl: './swiper-component.component.scss',
})
export class SwiperComponent implements OnInit {

  @Input() filteredSwipers: FilteredSwipersModel = {filteredRandomRestaurants: [] , filteredRandomDishes: [] , filteredRandomExperiences:[]};

  constructor() {}

  ngOnInit() {}
}
