<div class="px-2 md:px-8">
  <div class="px-1 mb-[15px] md:mb-8">
    <p class="text-quovo-red -mb-1 md:mb-[10px] text-lg md:text-3xl quovo-font-bold">{{ 'swiper.experiences-title' | translate }}</p>
    <p class="quovo-font-light mb-0 md:mb-3 text-sm md:text-2xl">{{ 'swiper.experiences-description' | translate }}</p>
    <a class="hidden md:inline-block text-quovo-red underline quovo-font-light text-2xl hover:cursor-pointer" (click)="goToExperiences()"
       routerLink="/restaurants/all">{{ 'swiper.go-to-experiences' | translate }}</a>
  </div>

  <div>
    <div class="mb-[16px]">
      <ng-container *ngIf="experiences?.length; else noDataExperience" >
        <swiper-container #swiperEl slides-per-view="5" [breakpoints]="breakpoints"  space-between="30" speed="500" css-mode="true" mousewheel-control="{enabled: true, forceToAxis: true}" class="flex justify-items-stretch items-stretch mb-[16px] md:mb-[0px]">
          <swiper-slide *ngFor="let item of experiences; let i=index" class="max-w-card pb-[12px] md:max-w-card-md self-stretch h-auto">
            <ng-container *ngTemplateOutlet="randomExperiences ; context:{ $implicit: i+1 , item: item }"></ng-container>
          </swiper-slide>
        </swiper-container>
      </ng-container>
    </div>
    <button class="flex md:hidden w-full rounded-md bg-quovo-red text-center text-white p-2.5 text-sm" (click)="goToExperiences()">
      <span class="mx-auto">{{ 'swiper.go-to-experiences' | translate }}</span>
    </button>
  </div>


  <ng-template #noDataExperience>
    <div class="swiper-slide">
      {{'common.no-data' | translate}}
    </div>
  </ng-template>

  <ng-template #randomExperiences let-item="item">
    <div class="flex flex-col shadow-[0px_3px_6px_#00000029] rounded-[15px] overflow-hidden bg-white h-full relative md:cursor-pointer"  (click)="onCardClick(item)">
      <div class="relative">
        <div class="absolute top-5 right-5 bg-white w-8 h-8 rounded-full p-2 flex align-items-center justify-center hover:cursor-pointer"
        (click)="onFavoriteClick($event, item)"
        >
          <i class="fa-regular fa-heart"></i>
        </div>
        <img [src]="item.imageUrl" alt="{{item.name}}" class="w-full object-cover rounded-t-xl aspect-square sm:col-span-2 lg:col-span-full" loading="lazy">
      </div>
      <div class="flex flex-col p-[10px_14px_16px] h-full">
        <p class="text-[22px] lh-[30px] text-quovo-red mb-[0px] lg:mb-3">{{ item.name }}</p>
        <p class="text-quovo-gray-dark text-xs md:text-base line-clamp-2 lg:line-clamp-3 xl:line-clamp-2 text-left text-wrap mb-2">
          {{ item.description }}
        </p>
        <p class="text-quovo-gray-dark text-[10px] md:text-[14px] text-left text-wrap overflow-ellipsis truncate mb-0 mt-auto">
          <span>{{ getCityTag(item) }}</span>
          <span class="px-1">|</span>
          <span>{{ getTypologyTag(item) }}</span>
        </p>
      </div>
    </div>
    <!-- <div class="px-1 pb-[12px]">
      <div class="max-w-4xl mx-auto grid grid-cols-1 rounded-lg shadow-[0px_3px_6px_#00000029]" (click)="onCardClick(item)">
        <div class="grid gap-4 col-start-1 col-end-3 row-start-1">
          <div class="absolute top-5 right-5 bg-white w-8 h-8 rounded-full p-2 flex align-items-center justify-center hover:cursor-pointer">
            <i class="fa-regular fa-heart"></i>
          </div>
          <img [src]="item.imageUrl" alt="" class="w-full h-60 object-cover rounded-t-xl sm:h-52 sm:col-span-2 lg:col-span-full" loading="lazy">
        </div>
        <div class="bg-white block p-3 rounded-b-lg">
          <div class="flex flex-col">
            <div class="flex-1">
              <p class="text-quovo-gray-dark text-lg md:text-base text-left text-wrap overflow-ellipsis truncate">
                <span>{{ getCityTag(item) }}</span>
                <span class="px-1">|</span>
                <span>{{ getTypologyTag(item) }}</span>
              </p>
              <p class="text-[22px] lh-[30px] text-quovo-red mb-0">
                {{ item.name }}
              </p>
            </div>
            <div class="flex-1">
              <p class="text-quovo-gray-dark text-xs line-clamp-2 lg:line-clamp-3 xl:line-clamp-2 text-left text-wrap mb-0">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </ng-template>
</div>
